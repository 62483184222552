import * as React from "react";
import 'src/main.scss';
import 'react-alice-carousel/lib/alice-carousel.css';
import wrapperClasses from 'src/components/page/wrapper.module.scss'
import classNames from 'classnames';
import { GoogleApiWrapper } from "google-maps-react";
import { googleMapsKey } from "../../constants/api";

const Page = ({ children, className }) => (<div className={classNames(wrapperClasses['wrapper'], className)}>
    {children}
</div>);

export default GoogleApiWrapper({
    apiKey: googleMapsKey,
    libraries: ["places"]
})(Page);
